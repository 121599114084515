@charset "utf-8";
/* 넓이고정 */
#wrap { width: 100%; max-width: 568px; margin: 0 auto;}

/* dep1 list */

#header nav.headNav {
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0) 0px, rgb(255, 255, 255) 42%);
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;
}

#header div.dep1NavList .dep1NavList-item > a {
    padding: 12.5px 16px;
    border: 0;
}
.dep1NavList-item > a > span {
    font-weight: 600;
}

/* dep1 list */

.navMenu {padding: 0 1rem;}
.section .navMenu {
    margin: -1rem 0 1.5rem;
    padding: 0;
}
.navMenu ul:after {content:"";display:block;clear:both;}
.navMenu li {
    float: left;
    width: 20%;
    text-align: center;
}
.navMenu a {
    display: block;
    margin: 0 1rem;
    font-weight: bold;
}
.navMenu a:before {
    content: "";
    display: block;
    height: 4rem;
    margin: 1rem 0;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: 50%;
}
.navMenu .navNews a:before {background-image: url('https://image.t-page.co.kr/resource/images/icon_nav_news.png');}
.navMenu .navGame a:before {background-image: url('https://image.t-page.co.kr/resource/images/icon_nav_game.png');}
.navMenu .navShoping a:before {background-image: url('https://image.t-page.co.kr/resource/images/icon_nav_shoping.png');}
.navMenu .navMap a:before {background-image: url('https://image.t-page.co.kr/resource/images/icon_nav_map.png');}
.navMenu .navSearch a:before {background-image: url('https://image.t-page.co.kr/resource/images/icon_nav_search.png');}

.dataChart {
    padding: 1rem 0;
    font-size: 0;
    text-align: center;
}
.main .dataChart {margin-top: 2rem;}
.dataChart li {
    position: relative;
    display: inline-block;
    padding: 0 .5rem 0 3.5rem;
    font-size: 1rem;
    text-align: left;
}
.dataChart .chart {
    position: absolute;
    top: 0; left: 0;
    width: 3rem;
}
.dataChart .chart img {width: 100%;}

.weatherWrap {
    position: relative;
    box-sizing: border-box;
    height: 6.2rem;
    padding: .7rem 0 0 8rem;
    border-top: .7rem solid #F3F3F3;
    font-size: 1.1rem;
}
.weatherWrap .state {
    position: absolute;
    top: 0; bottom: 0; left: 2.5rem;
}
.weatherWrap .state img {
    width: 4.5rem;
    vertical-align: middle;
}
.weatherWrap .state:after {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}
.weatherWrap .area {
    display: block;
    font-size: 1.6rem;
}
.weatherWrap .temp {
    position: absolute;
    top: .8rem; right: 3rem;
    font-size: 2.5rem;
    font-weight: bold;
}

.realTimeKeyword {
    position: relative;
    height: 1.8rem;
    padding: .9rem 4.5rem .9rem 9.5rem;
    box-sizing: unset;
}
.realTimeKeyword + .dataChart {border-top: .1rem solid #ededed;}
.realTimeKeyword .title {
    position: absolute;
    top: .75rem; left: 1.5rem;
    padding: 0.38rem .5rem;
    background: #EA002C;
    color: #fff;
    font-size: 1.1rem;
    font-weight: 600;
}
.realTimeKeyword .list {
    overflow: hidden;
    height: 1.8rem;
    font-size: 1.25rem;
    font-weight: bold;
}
.realTimeKeyword .list li {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

}
.realTimeKeyword .list .num {
    display: inline-block;
    margin: 0 .5rem 0 0;
    color: #EA002C;
    font-style: italic;
}
.realTimeKeyword .btn {
    position: absolute;
    top: 0; right: 0;
    width: 3rem;
    height: 100%;
    background: url('https://image.t-page.co.kr/resource/images/icon_arrow.png') no-repeat 0 50%;
    background-size: .9rem;
    font-size: 0;
}

.realTimeKeyword .btn1 {
    position: absolute;
    top: 0; right: 0;
    width: 3rem;
    height: 100%;
    background: url('https://image.t-page.co.kr/resource/images/icon_arrow.png') no-repeat 0 50%;
    background-size: .9rem;
    font-size: 0;
}

.realTimeKeyword .btn2 {
    position: absolute;
    top: 0; right: 0;
    width: 3rem;
    height: 100%;
    background: url('https://image.t-page.co.kr/resource/images//keyword/list_close.png') no-repeat 0 50%;
    background-size: .9rem;
    font-size: 0;
}

.banner {border-top: .7rem solid #F3F3F3;}
.banner img {width: 100%;}

.linkList {
    position: relative;
    padding: 0 1.5rem;
    border-top: .7rem solid #F3F3F3;
}
.linkList > ul {
    margin: 0;
}
.linkList:first-child {border: 0;}
.linkList .more {
    position: absolute;
    top: 1rem; right: 1rem;
    font-size: 1.1rem;
    color: #757575;
}
.linkList .more:before {
    content: "+";
    display: inline-block;
    margin-right: .3rem;
    vertical-align: .2rem;
}
.linkList .txtType li {
    line-height: 2;
    border-top: .1rem solid #ededed;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.linkList .txtType .adWrap {
    overflow: visible;

}
.linkList .txtType li:first-child {border: 0;}
.linkList .txtType li .num {
    margin-right: .5rem;
    color: #EA002C;
}

.adWrap a {
    position: relative;
    display: block;
    margin: 1.5rem 0 2rem;
    padding: 1rem;
    border: 1px solid #ededed;
    box-shadow: .2rem .2rem .5rem rgba(0,0,0,.07);
}
.adWrap .tit {
    display: block;
    font-size: 1.6rem;
    line-height: 1;
}
.adWrap .ad {
    position: absolute;
    top: .5rem; right: 1rem;
    font-size: 1.2rem;
    color: #9F9F9F;
}
.adWrap .img {
    display: block;
    margin: 1rem 0;
}
.adWrap .bot {
    display: block;
    position: relative;
    margin: 0 0 .5rem;
    padding: .5rem 0;
}
.adWrap .txt {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 6.5rem;
    font-size: 1.25rem;
    color: #222;
    line-height: 1;
}
.adWrap .btn {
    position: absolute;
    bottom: 0; right: 0;
    padding: 0 .5rem;
    font-size: 1.1rem;
    color: #3A8ACF;
    border: 1px solid #3A8ACF;
    border-radius: .3rem;

}


.linkList .srcType li {
    border-top: .1rem solid #ededed;
}
.linkList .srcType li:first-child {border: 0;}
.linkList .srcType a {
    width: 100%;
    position: relative;
    display: inline-block;
    max-width: 100%;
    box-sizing: border-box;
    padding-right: 5.9rem;
    line-height: 2;
}
.linkList .srcType .txt {
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1;
}
.linkList .srcType .source {
    position: absolute;
    top: .7rem; right: 0;
    color: #7C7C7C;
    line-height: 1;
    font-size: 0.75em;
}
.linkList .thumbType {margin: 0 -.2rem;}
.linkList .thumbType li {
    float: left;
    width: 33.33%;
}
.linkList .thumbType:after {
    content: "";
    display: block;
    clear: both;
}
.linkList .thumbType .thumb {
    display: block;
    border: .1rem solid #ededed;
}
.linkList .thumbType img {width: 100%;}
.linkList .thumbType a {
    display: block;
    margin: 0 .2rem .5rem;
    text-align: center;
}
.linkList .thumbType .txt {
    margin: .5rem 0 1rem;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 1.2rem;
    line-height: 1.3;
}

.linkList .thumbType .txt2 {
    margin: .5rem 0 1rem;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    /*! autoprefixer: off */
    -webkit-box-orient: vertical;
    /*! autoprefixer: on */
    font-size: 1.2rem;
    line-height: 1.3;
}
.linkList .thumbBigType {margin: 0 -.2rem;}
.linkList .thumbBigType:after {
    content: "";
    display: block;
    clear: both;
}
.linkList .thumbBigType li {
    position: relative;
    float: left;
    width: 33.33%;
    padding: 33.33% 0 0;
}
.linkList .thumbBigType li:first-child {
    width: 66.66%;
    padding: 66.66% 0 0;
}
.linkList .thumbBigType a {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    overflow: hidden;
    display: block;
    margin: .2rem;
    border: 1px solid #ededed;
}
.linkList .thumbBigType img {width: 100%;}
.linkList .thumbBigType .txt {
    position: absolute;
    bottom: 0; right: 0; left: 0;
    padding: 5rem .5rem 1rem;
    background-image: linear-gradient(transparent, #000);
    color: #fff;
    font-size: 1rem;
}
.linkList .thumbBigType .txt b {font-size: 1.2rem;}

.linkList .newsType li {
    position: relative;
    margin: 0 0 2rem;
    padding: 0 0 0 9.5rem;
}
.linkList .newsType .thumb {
    position: absolute;
    top: 0; left: 0;
    width: 8.5rem;
    margin: -0.3rem 0 0;
}
.linkList .newsType .source {
    display: block;
    line-height: 0;
}
.linkList .newsType .source img {height: 1.4rem;}
.linkList .newsType .tit {
    display: -webkit-box;
    height: 3.2rem;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
    line-height: 1.3;
    font-size: 1.2rem;
    font-weight: bold;
    box-sizing: unset;
}
.linkList .newsType .opt {
    display: block;
    width: 100%;
    margin: .5rem 0 0;
    overflow: hidden;
    color: #A5A5A5;
    font-size: .8rem;
    line-height: 1.2;
}
.linkList .newsType .opt .left {float: left;}
.linkList .newsType .opt .right {float: right;}

.linkList .newsType2 li {
    padding: 1rem 0;
    border-top: 1px solid #ededed;
}
.linkList .newsType2 li:first-child {border: 0;}
.linkList .newsType2 a {
    position: relative;
    display: block;
    padding: 0 0 0 8rem;
}
.linkList .newsType2 .thumb {
    position: absolute;
    top: 0; left: 0;
    width: 7rem;
    height: 5rem;
    overflow: hidden;
}
.linkList .newsType2 .thumb > img {
    width: 100%;
    height: 100%;
}
.linkList .newsType2 .txt {
    display: -webkit-box;
    height: 3.4rem;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
    font-size: 1.4rem;
    line-height: 1.2;
}
.linkList .newsType2 .opt {
    display: block;
    width: 100%;
    margin: .2rem 0 0;
    overflow: hidden;
    color: #A5A5A5;
    font-size: 1.1rem;
    line-height: 1.2;
}

/* ///////////////////////////////////////////////// */
.linkList .newsContent li {
    padding: 1rem 0;
    border-top: 1px solid #ededed;
}
.linkList .newsContent li:first-child {border: 0;}
.linkList .newsContent a {
    position: relative;
    display: block;
    /* padding: 0 0 0 8rem; */
}
.linkList .newsContent .thumb {
    display: block;
    width: 100%;
    margin: 0 auto;
    text-align: center;
}
.linkList .newsContent .thumb img {
    height: 230px;
}
.linkList .newsContent .txt {
    display: -webkit-box;
    /* height: 3.4rem; */
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    font-size: 1.4rem;
    line-height: 1.2;
    font-weight: bold;
    margin: 10px 0;
}
.linkList .newsContent .opt {
    display: block;
    width: 100%;
    margin: .2rem 0 .5rem;
    overflow: hidden;
    color: #A5A5A5;
    font-size: 1.1rem;
    line-height: 1.2;
}
.linkList .newsContent .content {
    font-size: 1.1rem;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
}
.linkList .newsContent .url {
    display: block;
    width: 100%;
    margin: 1rem 0 0;
    overflow: hidden;
    font-size: 1.1rem;
    line-height: 1.2;
    text-align: right;
}
/* ///////////////////////////////////////////////// */


.linkList .gridType {
    width: 100%;
    overflow: hidden;
}
.linkList .gridType:last-child {margin: 0 0 1rem;}
.linkList .gridType ul {margin: 0 -.25rem;}
.linkList .gridType li {
    float: left;
    width: 50%;
    box-sizing: border-box;
    padding: .25rem;
}
.linkList .gridType .itemLeng3 li {width: 33.33%;}
.linkList .gridType .thumb {
    display: block;
    border: 1px solid #ededed;
}

/* new work */
.photoAlb {
    display: flex;
    -webkit-display: flex;
    -ms-display: flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
    -ms-justify-content: flex-start;
}

.photoAlb > li {
    width: 33%;
}

.photoAlb > li:first-child,
.photoAlb > li:nth-child(4n) {
    padding: 0 2px 0 0;
}
.photoAlb > li:nth-child(3n) {
    padding: 0 0 0 2px;
}

.photoAlb > li > a {
    display: block;
    width: 100%;
}

.photoAlb > li > a > span.thumb {
    display: block;
    max-height: 110.953px;
    overflow:hidden;
    border: 1px solid #ededed;
}

.photoAlb > li > a > span.txt {
    display: block;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 1.2rem;
}

@media (max-width:687px){
    .photoAlb > li > a > span.thumb {
        max-height: 64px;
    }
}

/* new work end */


/* feed gameN */
.linkList .gridType .txt_gamen {
    padding: 0 0 0 .2rem;
    margin: .5rem 0 0;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 1.2rem;

    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    overflow: hidden;
    /*white-space: nowrap;*/
    word-wrap: normal !important;
    display: block;
    height: 1.5rem;
}

.linkList .gridType .opt_gamen {
    color:#888;
    padding: 0 0 0 .2rem;
    margin: .5rem 0 0;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 1.0rem;

    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    overflow: hidden;
    /*white-space: nowrap;*/
    word-wrap: normal !important;
    display: block;
    height: 1.5rem;
}

/* feed gameN End */


.linkList .gridType .txt {
    padding: 0 0 0 .2rem;
    margin: .5rem 0 0;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 1.2rem;
    line-height: 1.3;
    text-align: left;

    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    overflow: hidden;
    /*white-space: nowrap;*/
    word-wrap: normal !important;
    display: block;
    height: 3rem;
}


.listNav {
    margin: .5rem 0 2rem;
    text-align: center;
}
.listNav .pagination {
    display: inline-block;
    margin: 0 1rem;
    vertical-align: middle;
}
.listNav .pagination strong {color: #EA002C;}
.listNav [class*="btn"] {
    font-size: 0;
    width: 2.2rem; height: 2.2rem;
    border: 1px solid #bbb;
    vertical-align: middle;
}
.listNav [class*="btn"]:after {
    content: "";
    display: block;
    width: .8rem; height: .8rem;
    border-top: .1rem solid #979797;
    border-right: .1rem solid #979797;
}
.listNav .btnPrev:after {margin-left: .8rem;transform: rotate(-135deg);}
.listNav .btnNext:after {margin-left: .3rem;transform: rotate(45deg);}

.tabMenu {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-top: .7rem solid #F3F3F3;
}
.tabMenu ul {margin: -1px 0 0 -1px;}
.tabMenu .menuLeng3 li {width:33.33%;}
.tabMenu .menuLeng4 li {width:25%;}
.tabMenu li {float: left;}
.tabMenu li a {
    display: block;
    border-top: 1px solid #E6E6E6;
    border-left: 1px solid #E6E6E6;
    font-size: 1.3rem;
    line-height: 3.7rem;
    text-align: center;
}
.tabMenu li.active a {color: #EA002C;}

.breakingNews {
    position: relative;
    padding: .8rem 1rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;
}
.breakingNews span {
    padding: 0 .5rem;
    border: .1rem solid #EA002C;
    color: #EA002C;
    font-size: 1.1rem;
    border-radius: 2rem;
}

.pressList {
    width: 100%;
    overflow: hidden;
    text-align: center;
}
.pressList li {
    width: 25%;
    float: left;
    margin: 1rem 0;
    font-size: 1.25rem;
}
.pressList li img {
    display: block;
    width: 4rem;
    margin: 0 auto .5rem;
    border-radius: 1.5rem;
    border: 1px solid #ededed;
}

.gameList {margin: -1rem 0 0;}
.gameList li {margin: 1rem 0;}
.gameList a {
    position: relative;
    display: block;
    min-height: 6rem;
    padding: 0 0 0 11rem;
}
.gameList .img {
    position: absolute;
    top: 0; left: 0;
    width: 9.5rem;
}
.gameList .name {
    display: block;
    padding: 1rem 0 0;
    font-size: 1.3rem;
    font-weight: bold;
}
.gameList .name .state {
    display: inline-block;
    vertical-align: .1rem;
    padding: 0 .2rem;
    background: #EA002C;
    color: #fff;
    font-size: 1rem;
    font-weight: normal;
}
.gameList .opt {
    font-size: 0;
    color: #888;
}
.gameList .opt span {
    display: inline-block;
    line-height: 1;
    font-size: 1.1rem;
}
.gameList .opt span ~ span {
    margin-left: .5rem;
    padding-left: .5rem;
    border-left: .1rem solid #ddd;
}

.gameList2 {margin: -1rem 0 0;}
.gameList2 li {margin: 1rem 0;}
.gameList2 li a {
    position: relative;
    display: block;
    box-sizing: border-box;
    padding: 0 10rem 0 0;
    min-height: 4.7rem;
    font-size: 1.2rem;
}
.gameList2 .img {
    position: absolute;
    top: 0; right: 0;
    width: 9.5rem;
}
.gameList2 .img img {width: 100%;}
.gameList2 .tit {
    display: -webkit-box;
    line-height: 1.3;
    font-weight: bold;
    height: 3rem;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.gameList2 .txt {
    display: block;
    margin: .2rem 0 0;
    font-size: 1rem;
    color: #888;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.gameList3 {margin: -2rem 0 0;}
.gameList3 li {margin: 2rem 0;}
.gameList3 a {
    position: relative;
    display: block;
    min-height: 6.3rem;
    padding: 0 0 0 9.5rem;
}
.gameList3 .img {
    position: absolute;
    top: -0.6rem; left: 0;
    width: 8.5rem;
    border: .1rem solid #ededed;
}
.gameList3 .tit {
    display: block;
    font-weight: bold;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.gameList3 .txt {
    display: -webkit-box;
    margin: .5rem 0;
    line-height: 1.2;
    font-size: 1.2rem;
    height: 2.9rem;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.gameList3 .opt {
    display: block;
    font-size: 0;
    color: #A5A5A5;
    text-align: right;
}
.gameList3 .opt span {
    display: inline-block;
    line-height: 1;
    font-size: 1rem;
}
.gameList3 .opt span {
    margin-right: .5rem;
    padding-right: .5rem;
    border-right: .1rem solid #ddd;
}
.gameList3 .opt span:last-child {margin:0;padding:0;border:0;}
.gameList3 .opt .reply {float: left;margin:0;padding:0;border:0;}
.gameList3 .opt .date {color: #F86153;}

.shopType li {margin: 2rem 0;}
.shopType li a {
    display: block;
    position: relative;
    min-height: 6.3rem;
    padding: .5rem 0 0 9.5rem;
    font-size: 1.2rem;
    box-sizing: border-box;
}
.shopType .thumb {
    position: absolute;
    top: 0; left: 0;
    width: 8.5rem;
    border: 1px solid #ededed;
}
.shopType .txt {
    display: -webkit-box;
    line-height: 1.2;
    height: 2.9rem;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: bold;
}
.shopType .price {
    display: block;
    margin: .5em 0 0;
}
.shopType .price del {color: #9F9F9F;}

.appList {
    overflow-x: auto;
    padding: 0 0 2rem;
    -webkit-overflow-scrolling: touch;
}
.appList ul {
    display: table;
    margin: 0 -.2rem;
}
.appList li {display: table-cell;}
.appList a {
    display: block;
    box-sizing: border-box;
    width: 8.6rem;
    margin: 0 .2rem;
    padding: .8rem;
    border: 1px solid #ededed;
    border-radius: .4rem;
    box-shadow: .2rem .2rem .2rem rgba(0,0,0,.05);
}
.appList .icon img {border-radius: 1rem;}
.appList .name {
    display: block;
    height: 3rem;
    margin: .5rem 0 0;
    font-size: 1.2rem;
    line-height: 1.2;
}
.appList .make {
    display: block;
    font-size: .9rem;
    margin: .3rem 0 0;
}
.appList [class*="score"] {
    position: relative;
    display: block;
    width: 8rem; height: .7rem;
    margin: .5rem 0 0;
    font-size: 0;
    background: url('https://image.t-page.co.kr/resource/images/bg_score.gif') no-repeat;
    background-size: auto 100%;
}
.appList [class*="score"]:before {
    content: "";
    position: absolute;
    top: 0; left: 0;
    height: .7rem;
    background: url('https://image.t-page.co.kr/resource/images/bg_score_active.gif') no-repeat;
    background-size: auto 100%;
}
.appList .score1:before {width:10%;}
.appList .score2:before {width:20%;}
.appList .score3:before {width:30%;}
.appList .score4:before {width:40%;}
.appList .score5:before {width:100%;}

.relatedKeywrod {
    position: relative;
    height: 1.8rem;
    padding: .9rem 6rem .9rem 1rem;
    font-size: 1.3rem;
    border-bottom: 1px solid #ededed;
}
.relatedKeywrod .tit {
    display: inline-block;
    margin: 0 .5rem 0 0;
}
.relatedKeywrod .keyword {
    display: inline-block;
}
.relatedKeywrod .more {
    position: absolute;
    top: .8rem; right: .5rem;
    color: #757575;
}
.relatedKeywrod .more:before {
    content: "+";
    display: inline-block;
    margin: 0 .3rem 0 0;
    vertical-align: .2rem;
}

.searchResult {
    position: relative;
    padding: 0 0 .8rem;
    border-top: 1px solid transparent;
}
.searchResult .resultCard {
    margin: .8rem 1.4rem 0;
    background: #fff;
    border: 1px solid #D5D8DE;
    border-radius: .4rem;
    box-shadow: .3rem .3rem rgba(0,0,0,.02);
}
.searchResult .typeLink .name {
    padding: 1rem;
    font-size: 1.3rem;
    color: #006DD9;

}
.searchResult .typeLink .name .link {
    display: block;
    font-size: 1.1rem;
}
.searchResult .typeLink .cont {
    padding: 1rem;
    border-top: 1px solid #D5D8DE;
    font-size: 1.2rem;
}
.searchResult .cardTitle {
    padding: 1rem;
    font-size: 1.3rem;
}
.searchResult .typeList {
    border-top: 1px solid #D5D8DE;
    padding: 0 1.5rem;
}
.searchResult .typeList li {
    margin: 2rem 0;
}
.searchResult .typeList a {
    position: relative;
    display: block;
    height: 6rem;
    padding: 0 0 0 7.5rem;
}
.searchResult .typeList .thumb {
    position: absolute;
    top: 0; left: 0;
    width: 6rem;
}
.searchResult .typeList .thumb img {
    border-radius: .4rem;
}
.searchResult .typeList .tit {
    display: block;
    font-size: 1.3rem;
    color: #006DD9;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.searchResult .typeList .txt {
    display: -webkit-box;
    height: 2.9rem;
    margin: .5rem 0 0;
    overflow: hidden;
    font-size: 1rem;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.tos, .privacypolicy {
    position: relative;
    padding: 0 1.5rem;
    line-height: 1.8;
    font-size: 0.8em;
}

/* 광고/제휴 문의 */
.inquiry { position: relative; padding: 0 1.5rem 1rem;}
.inquiry .subTitle1 { padding: 1rem 0 .5rem; font-size: 1.5rem; }
.inquiry .q_title { width: 22%; text-align: left; }
.inquiry .q_title h4 { line-height: 3.4rem; font-size: 1.3rem;}

.inquiry .q_wrap { display: flex; margin-bottom: 1.2rem;}
.inquiry .q_wrap:last-child { display: block; margin-bottom: 1.2rem;}

.inquiry .q_wrap .q_info { width: 78%; }
.inquiry .q_wrap .q_info { display: flex; align-items: center;}
.inquiry .q_wrap .q_info label { font-size: 1.3rem;}
.inquiry .q_wrap .q_info input[type="radio"] { -webkit-appearance: unset; width: 1.5rem; height: 1.5rem;  border: solid 2px #dbdbdb; vertical-align: middle; margin-right: 8px;}

.inquiry .q_wrap .q_info input[type="radio"]:checked {
    border: none;
    background-image: url('https://image.t-page.co.kr/resource/images/icon/qtype_ck.svg');
    /* background-image: url('http://image-dev.t-page.co.kr/resource/images/icon/qtype_ck.svg'); */
    background-position: center center;
    background-size: 100%;
}

.inquiry .q_wrap .q_info input[type="text"]{ border-radius: 3px; border: solid 1px #dbdbdb; height: 3rem;width: 100%; font-size: 1.2rem; padding-left: 1rem;}
.inquiry .q_wrap .q_info div { width: 30%;  line-height: 2.8rem;}

.inquiry textarea { width: 100%; font-size: 1.1rem; height: 200px;}

.inquiry .q_wrap.agree h5{ margin-bottom: .8rem;}
.inquiry .q_wrap.agree h5 small{ color: #7c7c7c; letter-spacing: -0.08em; line-height: 2rem; }
.inquiry .q_wrap.agree input[type="checkbox"] { margin-right: .5rem;}
.inquiry .q_wrap.agree label { font-size: 1.1rem; vertical-align: text-bottom; color: #7c7c7c; font-weight: bold;}
.inquiry .q_wrap.agree input[type=button] { border-radius: 3px; width: 100%; max-width: 115px; background: #ea002c; color: #fff; border: none; display: block; margin:  1.5rem auto 2.5rem; height: 3.5rem; font-size: 1.5rem;}

/* 1:1 문의하기 */
.inquiry > div.custom-btn-group {
    padding: 18px 0 0;
    display:flex;-webkit-display: flex;-ms-display: flex;-o-display: flex;}
.inquiry > div.custom-btn-group > div { margin: 0 auto; border: 1px solid #F3F3F3; }
.inquiry > div.custom-btn-group > div > button { font-size: 16px; }
.inquiry > div.custom-btn-group > div > button:last-child { font-size: 16px; border-left: 1px solid #F3F3F3; }
.inquiry > div.custom-btn-group > div > button.active { background-color: #F3F3F3; }
.inquiry > div.custom-btn-group > div > button:focus { outline-color: #EA002C; }

.inquiry > div.inquiry-list-place {
    min-height: 500px;
}

.inquiry > div.inquiry-list-place > div#answer_list {
    margin: 10px 0 0;
}

div.content-inquiry-info,
div.comment-answer-info {
    font-size: 13px;
    font-weight: 600;
    text-align: right;
    margin: 8px 0 0;
}
/* 1:1 문의하기 end */

@media (min-width:568px){
    .linkList .thumbType .thumb { margin: 0 auto; height: 100px; width: 100%; border: none;}
    .linkList .thumbType img { width: unset; height: 100% !important;}
}

/* new work! */
/* feed 날씨 앱 */
.weatherApp {border-top:.7rem solid #F3F3F3; padding: 15px 0;}
.weatherApp > div > div > div > div > a > div{display:flex;-webkit-display: flex;-ms-display: flex;-o-display: flex; padding:24px 0; width:100%; height: 96px; align-items:center; font-size:16px;}
.weatherApp > div > div > div > div > a > div > div:nth-child(1){width:35%; text-align:center}
.weatherApp > div > div > div > div > a > div > div:nth-child(2){width:42%; height:96px}
.weatherApp > div > div > div > div > a > div > div:nth-child(3){width:23%; height:96px}

.weatherApp > div > div > div > div > a > div > div:nth-child(1) > img{width:96px; height:96px}
.weatherApp > div > div > div > div > a > div > div:nth-child(2) > div:nth-child(1){height:65%; font-size:48px}
.weatherApp > div > div > div > div > a > div > div:nth-child(2) > div:nth-child(1) > div{margin:auto 0}

.weatherApp > div > div > div > div > a > div > div:nth-child(2) > div:nth-child(2){height:35%; display:flex;-webkit-display: flex;-ms-display: flex;-o-display: flex; align-items:flex-end}
.weatherApp > div > div > div > div > a > div > div:nth-child(2) > div:nth-child(2) > img{width:24px; height:24px; margin:0 4px}

.weatherApp > div > div > div > div > a > div > div:nth-child(3) > div:nth-child(1){height:70%; display:flex;-webkit-display: flex;-ms-display: flex;-o-display: flex; align-items:flex-end}
.weatherApp > div > div > div > div > a > div > div:nth-child(3) > div:nth-child(2){height:30%; display:flex;-webkit-display: flex;-ms-display: flex;-o-display: flex; align-items:flex-start}
.weatherApp > div > div > div > div > a > div > div:nth-child(3) > div > div{margin:0 auto;}

/* detail box */
.weatherDetail{width: 100%; overflow-x: scroll; -ms-overflow-style: none}
.weatherDetail::-webkit-scrollbar {display: none}
.weatherDetailScroll{display: flex;}
.weatherDetailScroll > div{width: 100%;}

/* detail 상단 오늘의 날씨 */
.weatherNowBox{position:relative;}
.weatherNowBox > div > a{content:''; position:absolute; top:0; right:0; width:24px; height:24px;
                     margin:24px 18px;}
.weatherNowBox > div{display:flex;-webkit-display: flex;-ms-display: flex;-o-display: flex; padding:24px 0; width:100%; height: 96px; align-items:center; font-size:16px;}
.weatherNowBox > div > div:nth-child(1){width:35%; text-align:center}
.weatherNowBox > div > div:nth-child(2){width:42%; height:96px}
.weatherNowBox > div > div:nth-child(3){width:23%; height:96px}

.weatherNowBox > div > div:nth-child(1) > img{width:96px; height:96px}
.weatherNowBox > div > div:nth-child(2) > div:nth-child(1){height:65%; font-size:48px}
.weatherNowBox > div > div:nth-child(2) > div:nth-child(1) > div{margin:auto 0}

.weatherNowBox > div > div:nth-child(2) > div:nth-child(2){height:35%; display:flex;-webkit-display: flex;-ms-display: flex;-o-display: flex; align-items:flex-end}
.weatherNowBox > div > div:nth-child(2) > div:nth-child(2) > img{width:24px; height:24px; margin:0 4px}

.weatherNowBox > div > div:nth-child(3) > div:nth-child(1){height:70%; display:flex;-webkit-display: flex;-ms-display: flex;-o-display: flex; align-items:flex-end}
.weatherNowBox > div > div:nth-child(3) > div:nth-child(2){height:30%; display:flex;-webkit-display: flex;-ms-display: flex;-o-display: flex; align-items:flex-start}
.weatherNowBox > div > div:nth-child(3) > div > div{margin:0 auto;}

/* detail 시간 라인 */
.weatherHourLine{border-top:.7rem solid #F3F3F3; overflow-x: scroll; -ms-overflow-style: none}
.weatherHourLine::-webkit-scrollbar {display: none}
.weatherHourLine > div{display:flex;-webkit-display: flex;-ms-display: flex;-o-display: flex; justify-content:space-between; width:90%; font-size:13px; margin: 10px auto;}
.weatherHourLine > div > div > dl > dd:nth-child(2) > img{width:24px; height:24px}

/* detail 일주일 데이터 리스트 */
.weatherWeekList > div{width:90%; margin:0 auto;}
.weatherWeekList > div > ul > li{display:flex;-webkit-display: flex;-ms-display: flex;-o-display: flex; justify-content:space-between; padding:15px 0; font-size:16px; border-top:.1rem solid #ededed}
.weatherWeekList > div > ul > li > div:nth-child(1){width:33%; text-align:left}
.weatherWeekList > div > ul > li > div:nth-child(2){width:34%; text-align:center}
.weatherWeekList > div > ul > li > div:nth-child(2) > img{width:24px; height:24px}
.weatherWeekList > div > ul > li > div:nth-child(3){width:33%; text-align:right}

/* search 검색 박스 */
.weatherSearchBox{position: relative; font-size:16px; background-color:#dbdbdb;}
.weatherSearchBox > div:nth-child(1){text-align:center; padding:12px 0 0;}
.weatherSearchBox > div:nth-child(2){display:flex;-webkit-display: flex;-ms-display: flex;-o-display: flex; padding:12px 18px; justify-content:center}
.weatherSearchBox > div:nth-child(2) > span{display:block; width:32px; height:32px; background-color: #FFF;
    display:flex;-webkit-display: flex;-ms-display: flex;-o-display: flex;
    border-radius:5px 0 0 5px;}
.weatherSearchBox > div:nth-child(2) > span > img{width:24px; height:24px; margin: auto;}
.weatherSearchBox > div:nth-child(2) > input[type=text]{width:75%; border:0; outline:none; border-radius:0 5px 5px 0;}
.weatherSearchBox > div:nth-child(2) > a{padding:3px 8px;}

/* search result 박스 */
.weatherSearchResultBox{position: relative; font-size:16px; padding:14px 18px;}
.weatherSearchResultBox > ul > li > a{display:block; padding:8px 0;}
.weatherSearchResultBox > ul{height:150px; overflow-y: scroll;-ms-overflow-style: none;}
.weatherSearchResultBox > ul::-webkit-scrollbar {display: none;}


/* search history 박스 */
.weatherHistoryList {min-height: 230px; background-color: #f3f3f3;
    display:flex;-webkit-display: flex;-ms-display: flex;-o-display: flex;
    flex-direction:column;-webkit-flex-direction:column;-ms-flex-direction:column;-o-flex-direction:column;}
.weatherHistoryList > div{position: relative; width: 90%; background-color: #FFF; margin: 7.5px auto;}
.weatherHistoryList > div:first-child{margin-top: 15px;}
.weatherHistoryList > div:last-child{margin-bottom: 15px;}
.weatherHistoryList > div > a.historyBox{display:flex;-webkit-display: flex;-ms-display: flex;-o-display: flex; width:100%; height:95px;}
.weatherHistoryList > div > a.historyBox > div{display:flex;-webkit-display: flex;-ms-display: flex;-o-display: flex; width:90%; height:80px; margin:auto}
.weatherHistoryList > div > a.historyBox > div > div:nth-child(1){display:flex;-webkit-display: flex;-ms-display: flex;-o-display: flex; flex-direction:column;-webkit-flex-direction:column;-ms-flex-direction:column;-o-flex-direction:column; width:50%;}
.weatherHistoryList > div > a.historyBox > div > div:nth-child(1) > p:nth-child(1){margin:auto auto 0 0; font-size:12px}
.weatherHistoryList > div > a.historyBox > div > div:nth-child(1) > p:nth-child(2){margin:0 auto auto 0; font-size:18px}
.weatherHistoryList > div > a.historyBox > div > div:nth-child(2){display:flex;-webkit-display: flex;-ms-display: flex;-o-display: flex; width:50%; font-size:48px}
.weatherHistoryList > div > a.historyBox > div > div:nth-child(2) > p{margin:auto 0 auto auto}

.weatherHistoryList > div > button.deleteHistoryList{position: absolute; top: 0; right: 0; width: 18px; height: 18px;}

.weatherHistoryList div.delete-history-slide {display:flex;-webkit-display: flex;-ms-display: flex;-o-display: flex; width: 120px; height: 100%;}
.weatherHistoryList div.delete-history-slide > div {margin: auto;color:#FFF;}

/* car component */

.carIt .linkList .listImg {margin:0 -1.5rem 1.5rem;}

.radiobMenu {
    width: 100%;
    overflow: hidden;
    border-top: .7rem solid #F3F3F3;
}
.radiobMenu ul {margin: -1px 0 0 -1px;}
.radiobMenu ul li a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {color: #EA002C;}
.radiobMenu .menuLeng2 li {width:50%;}
.radiobMenu .menuLeng3 li {width:33.33%;}
.radiobMenu .menuLeng4 li {width:25%;}
.radiobMenu li {float: left;}
.radiobMenu li a {
    display: block;
    border-top: 1px solid #E6E6E6;
    border-left: 1px solid #E6E6E6;
    font-size: 1.3rem;
    line-height: 3.7rem;
    text-align: center;
}
.radiobMenu li.active a {color: #EA002C;}

.carIt .linkList .newsType {margin-top:15px;}
.carIt .linkList .newsType li {margin-bottom:15px;height:67px;padding-left:124px;}
.carIt .linkList .newsType li .thumb {width:112px;height:72px;}
.carIt .linkList .newsType li .thumb img {width:112px;height:72px;}
.carIt .linkList .newsType li .tit {color:#000;padding-top:5px;height:37px;}
.carIt .linkList .newsType li .opt {color:#999;height:13px;white-space:nowrap;text-overflow:ellipsis;font-weight:bold;}
/* car component end */

#container {height: 100%;}

/* webtoon */
#webtoonFrame {display: block; border: 0; outline: 0; height: 100vh}
div.webtoonPage {
    position: relative;
}
div.webtoonBennerBox {margin: 0 -1.5rem;}

div.webtoonPointInfoBox {padding: 1rem 0; font-size: 16px;
    -webkit-display: flex; -ms-display: flex; -o-display: flex; display: flex;}
div.webtoonPointInfoBox > div {margin: auto;}
div.webtoonPointInfoBox > div:nth-child(1) {width:30%; text-align: left}
div.webtoonPointInfoBox > div:nth-child(2) {width:35%; text-align: left; font-weight: 600; color: #e9001c;}
div.webtoonPointInfoBox > div:nth-child(3) {width:35%; text-align: right}
div.webtoonPointInfoBox > div:nth-child(3) > a,
div.webtoonPointInfoBox > div:nth-child(3) > button {padding: 5px 10px; background-color: #e9001c; color: #FFF; font-size: 14px; margin: 0 5px 0 0;
    -webkit-border-radius: 13px; -ms-border-radius: 13px; -o-border-radius: 13px; border-radius: 13px;}
/* webtoon end */

/* member */
/* join */
div.loginPage, div.mypagePage, div.tpointPage,
div.joinPage {width: 100%; height: 100%; font-size: 15px;}
div.withdrawalPage > div.withdrawalPageHeader ,div.loginPage > div.loginPageHeader, div.mypagePage > div.mypagePageHeader, div.tpointPage > div.tpointPageHeader,
div.joinPage > div.joinPageHeader {position: relative; width: 100%; height: 54px; color: #ffffff; background-color: #333333;
    -webkit-display: flex; -ms-display: flex; -o-display: flex; display: flex; font-size: 16px;}
div.withdrawalPage > div.withdrawalPageHeader > a, div.loginPage > div.loginPageHeader > a, div.mypagePage > div.mypagePageHeader > a, div.tpointPage > div.tpointPageHeader > a,
div.joinPage > div.joinPageHeader > a {width: 24px; height: 24px; position: absolute; top: 30%; left: 5%;
    -ms-transform: translate(-30%, -5%); -webkit-transform: translate(-30%, -5%); -moz-transform: translate(-30%, -5%); transform: translate(-23%, -5%);}
div.withdrawalPage > div.withdrawalPageHeader > div, div.loginPage > div.loginPageHeader > div, div.mypagePage > div.mypagePageHeader > div, div.tpointPage > div.tpointPageHeader > div,
div.joinPage > div.joinPageHeader > div {width: 100%; margin: auto; text-align: center;}

div.joinPageBody > div.joinPageBodyInfo {padding: 2.5rem 1.5rem; background-color: #f3f3f3; font-size: 18px;}
div.joinPageBody > div.joinPageBodyInfo > dl {padding: 0; margin: 0;}
div.joinPageBody > div.joinPageBodyInfo > dl > dt {margin: 0 0 3px;}
div.joinPageBody > div.joinPageBodyInfo > dl > dd {font-weight: 600; font-size: 16px;}

div.joinPageBody > div.joinPageBodyForm {padding: 2.5rem 1.5rem; background-color: #FFF;}

div.joinPageBody > div.joinPageBodyForm > p.precautions {color: #e9001c; font-size: 12px; margin: 0 0 5px 0;}
div.joinPageBody > div.joinPageBodyForm > div.inputForm {width:100%; border: 3px solid #f3f3f3; border-left: 0;
    border-right: 0; padding: 10px 0; font-size: 14px;}
div.joinPageBody > div.joinPageBodyForm > div.inputForm > div:first-child,
div.joinPageBody > div.joinPageBodyForm > div.inputForm > div:nth-child(2) {margin: 0 0 10px;}
div.joinPageBody > div.joinPageBodyForm > div.inputForm > div {
    -webkit-display: flex; -ms-display: flex; -o-display: flex; display: flex;}
div.joinPageBody > div.joinPageBodyForm > div.inputForm > div > div:first-child {width: 30%;}
div.joinPageBody > div.joinPageBodyForm > div.inputForm > div > div:nth-child(2) {width: 70%;}
div.joinPageBody > div.joinPageBodyForm > div.inputForm > div > div:nth-child(2) {
    -webkit-display: flex; -ms-display: flex; -o-display: flex; display: flex;}
div.joinPageBody > div.joinPageBodyForm > div.inputForm > div > div:nth-child(2) > div:first-child {
    width: 60%; height: 35px; border: 1px solid #ccc;}
div.joinPageBody > div.joinPageBodyForm > div.inputForm > div > div:nth-child(2) > div:first-child > input[type=number]:focus {
    border: 0; outline: 0;}
div.joinPageBody > div.joinPageBodyForm > div.inputForm > div > div:nth-child(2) > div:first-child > input[type=number] {
    width: 84%; height: 100%; padding: 0 8px; border: 0;}
div.joinPageBody > div.joinPageBodyForm > div.inputForm > div > div:nth-child(2) > div:last-child {width: 40%;}
div.joinPageBody > div.joinPageBodyForm > div.inputForm > div > div:nth-child(2) > div:last-child > button {
    width: 100%; height: 100%; padding: 6.9px 0; margin: -0.3px 0 0; background-color: #e9001c;
    border: 1px solid #e9001c; color: #FFF;}
div.joinPageBody > div.joinPageBodyForm > div.inputForm > div:last-child{
    width: 100%; height: 35px;
    -webkit-display: flex; -ms-display: flex; -o-display: flex; display: flex;
    -webkit-justify-content: flex-end; -ms-justify-content: flex-end; -o-justify-content: flex-end; justify-content: flex-end;}
div.joinPageBody > div.joinPageBodyForm > div.inputForm > div:last-child > button {
    width: 70%; background-color: #e9001c; color: #FFF; padding: 7.15px 0;}

div.joinPageBody > div.joinPageBodyForm > div.passwordForm {border: 3px solid #f3f3f3; border-left: 0; border-right: 0; padding: 10px 0; font-size: 14px;}
div.joinPageBody > div.joinPageBodyForm > div.passwordForm > div:first-child {margin: 0 0 10px;}
div.joinPageBody > div.joinPageBodyForm > div.passwordForm > div {
    -webkit-display: flex; -ms-display: flex; -o-display: flex; display: flex;}

div.joinPageBody > div.joinPageBodyForm > div.passwordForm > div > div:first-child {width: 30%; height:35px;
    -webkit-display: flex; -ms-display: flex; -o-display: flex; display: flex;}
div.joinPageBody > div.joinPageBodyForm > div.passwordForm > div > div:first-child > span {padding: 0 0 0 8px; margin: auto 0;}
div.joinPageBody > div.joinPageBodyForm > div.passwordForm > div > div:last-child {width: 70%; height:35px; border: 1px solid #cccccc;}
div.joinPageBody > div.joinPageBodyForm > div.passwordForm > div > div:last-child > input[type=password] {width: 90.5%; height: 100%; padding: 0 8px; border: 0;}
div.joinPageBody > div.joinPageBodyForm > div.passwordForm > div > div:last-child > input[type=password]:focus {border: 0; outline: 0;}

div.joinPageBody > div.joinPageBodyForm > div.actionFrom > div {
    margin: 10px 0 0; font-size: 14px;
    -webkit-display: flex; -ms-display: flex; -o-display: flex; display: flex;
    -webkit-justify-content: center; -ms-justify-content: center; -o-justify-content: center; justify-content: center;}
div.joinPageBody > div.joinPageBodyForm > div.actionFrom > div > input[type=checkbox] {display: none;}
div.joinPageBody > div.joinPageBodyForm > div.actionFrom > div > label {display: block; width: 24px; height: 24px; background-color: #f3f3f3;
    margin: auto 0; margin-right: 5px;}
div.joinPageBody > div.joinPageBodyForm > div.actionFrom > div > span {margin: auto 0;}
/* div.joinPageBody > div.joinPageBodyForm > div.actionFrom > div > input[type=checkbox]:checked + label {background-image: url('http://image-dev.t-page.co.kr/resource/images/webtoon/check.png')} */
div.joinPageBody > div.joinPageBodyForm > div.actionFrom > div > input[type=checkbox]:checked + label {background-image: url('https://image.t-page.co.kr/resource/images/webtoon/check.png')}
div.joinPageBody > div.joinPageBodyButton {padding: 0 1.5rem;}
div.joinPageBody > div.joinPageBodyButton > button {width: 100%; height: 40px; background-color: #e9001c; color: #FFF; font-size: 14px;}
/* join end */

/* login */
div.loginPage > div.loginPageBody {padding: 0 1.5rem;}
div.loginPage > div.loginPageBody > div:first-child {padding: 2rem 0;}
div.loginPage > div.loginPageBody > div:first-child > div {width: 100%; height: 35px; border: 1px solid #cccccc;}
div.loginPage > div.loginPageBody > div:first-child > div:first-child {margin: 0 0 6px;}
div.loginPage > div.loginPageBody > div > button {width: 100%; height: 40px; background-color: #e9001c; color: #FFF; font-size: 14px;}
div.loginPage > div.loginPageBody > div:first-child > div > input[type=password],
div.loginPage > div.loginPageBody > div:first-child > div > input[type=text] {width: 92.9%; height: 100%; font-size: 14px; border: 0; padding: 0 8px;}
div.loginPage > div.loginPageBody > div:first-child > div > input[type=password]:focus,
div.loginPage > div.loginPageBody > div:first-child > div > input[type=text]:focus {border: 0; outline: 0;}
div.loginPage > div.loginPageBody > div:last-child {
    margin: 10px 0 0;
    font-size: 14px;
    -webkit-display: flex; -ms-display: flex; -o-display: flex; display: flex;
    -webkit-justify-content: center; -ms-justify-content: center; -o-justify-content: center; justify-content: center;}
div.loginPage > div.loginPageBody > div:last-child > span {margin: 0 10px;}
/* login end */

/* mypage */
div.mypagePage > div.mypagePageBody {padding: 2.5rem 1.5rem 0; font-size: 14px;}
div.mypagePage > div.mypagePageBody > div:first-child {padding: 15.5px 0; margin: 0 0 17px 0;}
div.mypagePage > div.mypagePageBody > div {border: 1px solid #cccccc; border-left: 0; border-right: 0; padding: 3.5px 0;
    -webkit-display: flex; -ms-display: flex; -o-display: flex; display: flex;}
div.mypagePage > div.mypagePageBody > div > div {
    -webkit-display: flex; -ms-display: flex; -o-display: flex; display: flex;}
div.mypagePage > div.mypagePageBody > div > div > span{font-weight: 600; padding: 0 0 0 10px; margin: auto 0;}
div.mypagePage > div.mypagePageBody > div > div > button {
    width: 100%; height: 40px; font-size: 14px;
    margin: auto; background-color: #e9001c; color: #FFF;}
div.mypagePage > div.mypagePageBody > div > div:first-child {width: 50%;}
div.mypagePage > div.mypagePageBody > div > div:last-child {width: 50%;}

div.mypagePage > div.mypagePageOpen {padding: 0.8rem 1.5rem 0; font-size: 14px;}
div.mypagePage > div.mypagePageOpen > div > div {
    -webkit-display: flex; -ms-display: flex; -o-display: flex; display: flex;}
div.mypagePage > div.mypagePageOpen > div > div > div {
    -webkit-display: flex; -ms-display: flex; -o-display: flex; display: flex;}
div.mypagePage > div.mypagePageOpen > div > div > div:first-child {width: 30%;}
div.mypagePage > div.mypagePageOpen > div > div > div:last-child {width: 70%;}
div.mypagePage > div.mypagePageOpen > div > div > div > span {padding: 0 0 0 5px; margin: auto 0;}
div.mypagePage > div.mypagePageOpen > div > div > div > input[type=password] {width: 100%; height: 36px;}
div.mypagePage > div.mypagePageOpen > div > div {margin: 0 0 5px 0;}
div.mypagePage > div.mypagePageOpen > div > div:nth-child(4) {width: 100%; padding: 0 0 0.8rem; margin: 0;
    -webkit-display: flex; -ms-display: flex; -o-display: flex; display: flex;
    -webkit-justify-content: flex-end; -ms-justify-content: flex-end; -o-justify-content: flex-end; justify-content: flex-end;}
div.mypagePage > div.mypagePageOpen > div > div:nth-child(4) > div {width: 70%;}
div.mypagePage > div.mypagePageOpen > div > div:nth-child(4) > div > button {width: 50%; height: 40px; font-size: 14px;}
div.mypagePage > div.mypagePageOpen > div > div:nth-child(4) > div > button:first-child {background-color: #e9001c; color: #FFF;}
div.mypagePage > div.mypagePageOpen > div > div:nth-child(4) > div > button:last-child {color: #e9001c; border: 1px solid #e9001c;}
div.mypagePage > div.mypagePageOpen > div:last-child {width: 100%; border-top: 1px solid #cccccc; color: #e9001c; font-size: 12px;
    text-align: center; padding: 8px 0 0;}

div.mypageMemberOut {width: 100%; padding: 10px 0;
    -webkit-display: flex; -ms-display: flex; -o-display: flex; display: flex;}
div.mypageMemberOut > a {
    width: 30%; font-size: 14px; height: 40px;
    margin: auto; background-color: #e9001c; color: #FFF;
    -webkit-display: flex; -ms-display: flex; -o-display: flex; display: flex;}
div.mypageMemberOut > a > span {
    margin: auto;
}

/* mypage end */

/* first page */
div.withdrawalPage > div.withdrawalPageBody {font-size: 14px; margin: 40px 0 0;
    -webkit-display: flex; -ms-display: flex; -o-display: flex; display: flex;
    -webkit-flex-direction: column; -ms-flex-direction: column; -o-flex-direction: column; flex-direction: column;}
div.withdrawalPage > div.withdrawalPageBody > h2 {
    font-size: 18px; font-weight: 600; border-top: 1px solid #EEE; border-bottom: 1px solid #EEE;
    padding: 10px 0; margin: 0;}
div.withdrawalPage > div.withdrawalPageBody > h2 > span {padding: 0 20px;}
div.withdrawalPage > div.withdrawalPageBody > div { padding: 20px 20px 10px; }
div.withdrawalPage > div.withdrawalPageBody > ul {padding: 0 20px 20px 20px; border-bottom: 1px solid #EEE;}
div.withdrawalPage > div.withdrawalPageBody > ul > li:first-child {margin: 0 0 10px;}
div.withdrawalPage > div.withdrawalPageBody > ul > li > input {width: 100%; height: 34px;}

div.withdrawalPage > div.withdrawalPageBody .withdrawalPasswordForm { padding: 20px; }
div.withdrawalPage > div.withdrawalPageBody .withdrawalPasswordForm > li:first-child { padding: 0 0 8px; }

div.withdrawalPage > div.withdrawalPageConf > div.actionFrom > a {
    margin: 10px 0 0; font-size: 14px;
    -webkit-display: flex; -ms-display: flex; -o-display: flex; display: flex;
    -webkit-justify-content: center; -ms-justify-content: center; -o-justify-content: center; justify-content: center;}
div.withdrawalPage > div.withdrawalPageConf > div.actionFrom > a > input[type=checkbox] {display: none;}
div.withdrawalPage > div.withdrawalPageConf > div.actionFrom > a > label {display: block; width: 24px; height: 24px; background-color: #f3f3f3;
    margin: auto 0; margin-right: 5px;}
div.withdrawalPage > div.withdrawalPageConf > div.actionFrom > a > span {margin: auto 0;}
/* div.withdrawalPage > div.withdrawalPageConf > div.actionFrom > a > input[type=checkbox]:checked + label {background-image: url('http://image-dev.t-page.co.kr/resource/images/webtoon/check.png')} */
div.withdrawalPage > div.withdrawalPageConf > div.actionFrom > a > input[type=checkbox]:checked + label {background-image: url('https://image.t-page.co.kr/resource/images/webtoon/check.png')}

div.withdrawalPage > div.withdrawalPageBody > button {
    width: 30%; font-size: 14px; height: 40px;
    margin: 20px auto 0; background-color: #e9001c; color: #FFF;
}
/* first page end */

/* tpage point */
div.tpointPage > div.tpointPageTab {background-color: #f3f3f3;
    -webkit-display: flex; -ms-display: flex; -o-display: flex; display: flex;}
div.tpointPage > div.tpointPageTab > button {width: 100%; height: 51px; font-size: 14px; font-weight: 600; color: #A6A6A6;}
div.tpointPage > div.tpointPageBody > div.historyList {font-size: 14px;}
div.tpointPage > div.tpointPageBody > div.historyList > ul {width: 100%; padding: 0; margin: 0; height: 550px; overflow-y: scroll;}
div.tpointPage > div.tpointPageBody > div.historyList > ul > li {border-bottom: 1px solid #f3f3f3; padding: 10px 1.5rem;
    -webkit-display: flex; -ms-display: flex; -o-display: flex; display: flex;}
div.tpointPage > div.tpointPageBody > div.historyList.charge > ul > li > div {
    width: 50%; text-align: center; color: #333;}
div.tpointPage > div.tpointPageBody > div.historyList.charge > ul > li > div:last-child {color: #e9001c;}

div.tpointPage > div.tpointPageBody > div.historyList.usage > ul > li > div:first-child > div {text-align: left; font-size: 14px;}
div.tpointPage > div.tpointPageBody > div.historyList.usage > ul > li > div:first-child > div:first-child {font-size: 12px;}
div.tpointPage > div.tpointPageBody > div.historyList.usage > ul > li > div {
    width: 50%; color: #333; text-align: center; margin: auto;}
div.tpointPage > div.tpointPageBody > div.historyList.usage > ul > li > div:last-child {color: #e9001c; font-size: 14px;}

/* tpage point end */

/* member end */

/* header */

/* side slide bar */
.customSlideSideBar {width: 80%; height: 100%; color: #333;}
.customSlideSideBar .slideSidebarBox {height: 60px; background-color: #333; color: #FFF; border-bottom: 8px solid #f3f3f3;}

.customSlideSideBar div.slideSidebarBox.after > div.sidebarHeader,
.customSlideSideBar div.slideSidebarBox.before > div.sidebarHeader {height: 100%; padding: 0 20px; z-index: 1;
    -webkit-display: flex; -ms-display: flex; -o-display: flex; display: flex;}
.customSlideSideBar div.slideSidebarBox.before > div.sidebarHeader > div {width: 50%;}
.customSlideSideBar div.slideSidebarBox.before > div.sidebarHeader > div:first-child {
    -webkit-display: flex; -ms-display: flex; -o-display: flex; display: flex;
    -webkit-justify-content: flex-start; -ms-justify-content: flex-start; -o-justify-content: flex-start; justify-content: flex-start;}
.customSlideSideBar div.slideSidebarBox.before > div.sidebarHeader > div:first-child > a {
    background-color: #e9001c; color: #FFF; font-size: 14px; padding: 9px 16px; margin-top: auto; margin-bottom: auto;
    -webkit-border-radius: 5px; -ms-border-radius: 5px; -o-border-radius: 5px; border-radius: 5px;}
.customSlideSideBar div.slideSidebarBox.before > div.sidebarHeader > div:last-child {
    -webkit-display: flex; -ms-display: flex; -o-display: flex; display: flex;
    -webkit-justify-content: flex-end; -ms-justify-content: flex-end; -o-justify-content: flex-end; justify-content: flex-end;}
.customSlideSideBar div.slideSidebarBox.before > div.sidebarHeader > div:last-child > button {color: #FFF; margin-top: auto; margin-bottom: auto;}

.customSlideSideBar div.slideSidebarBox.after > div.sidebarHeader > div:first-child {width: 80%;
    -webkit-display: flex; -ms-display: flex; -o-display: flex; display: flex;
    -webkit-justify-content: flex-start; -ms-justify-content: flex-start; -o-justify-content: flex-start; justify-content: flex-start;}
.customSlideSideBar div.slideSidebarBox.after > div.sidebarHeader > div:first-child > div {
    margin: auto 0;
    -webkit-display: flex; -ms-display: flex; -o-display: flex; display: flex;
    -webkit-align-items: flex-end; -ms-align-items: flex-end; -o-align-items: flex-end; align-items: flex-end;}
.customSlideSideBar div.slideSidebarBox.after > div.sidebarHeader > div:first-child > div > span:first-child {
    font-size: 20px;
}
.customSlideSideBar div.slideSidebarBox.after > div.sidebarHeader > div:first-child > div > span:last-child {
    font-size: 16px; margin: 0 0 0 10px;
}
.customSlideSideBar div.slideSidebarBox.after > div.sidebarHeader > div:last-child {width: 20%;
    -webkit-display: flex; -ms-display: flex; -o-display: flex; display: flex;
    -webkit-justify-content: flex-end; -ms-justify-content: flex-end; -o-justify-content: flex-end; justify-content: flex-end;}
.customSlideSideBar div.slideSidebarBox.before > div.sidebarHeader > div:last-child > button,
.customSlideSideBar div.slideSidebarBox.after > div.sidebarHeader > div:last-child > button {color: #FFF; margin-top: auto; margin-bottom: auto; width: 24px; height: 24px;}

.customSlideSideBar div.slideSidebarBox.after > div.sidebarBody {
    color: #333; padding: 0;}
.customSlideSideBar div.slideSidebarBox.after > div.sidebarBody > div.sidebarBodyInfo {
    padding: 30px 20px; border-bottom: 6px solid #f3f3f3;
    -webkit-display: flex; -ms-display: flex; -o-display: flex; display: flex;}
.customSlideSideBar div.slideSidebarBox.after > div.sidebarBody > div.sidebarBodyInfo > div:first-child {
    width: 70%;
    -webkit-display: flex; -ms-display: flex; -o-display: flex; display: flex;
    -webkit-flex-direction: column; -ms-flex-direction: column; -o-flex-direction: column; flex-direction: column;}
.customSlideSideBar div.slideSidebarBox.after > div.sidebarBody > div.sidebarBodyInfo > div:first-child > div:first-child {
    font-size: 12px;}
.customSlideSideBar div.slideSidebarBox.after > div.sidebarBody > div.sidebarBodyInfo > div:first-child > div:last-child > div{
    -webkit-display: flex; -ms-display: flex; -o-display: flex; display: flex;
    -webkit-align-items: flex-end; -ms-align-items: flex-end; -o-align-items: flex-end; align-items: flex-end;}
.customSlideSideBar div.slideSidebarBox.after > div.sidebarBody > div.sidebarBodyInfo > div:first-child > div:last-child > div
> span:first-child {
    font-size: 24px; color: #e9001c; margin: 0 7px 0 -3px;}
.customSlideSideBar div.slideSidebarBox.after > div.sidebarBody > div.sidebarBodyInfo > div:first-child > div:last-child > div
> span:last-child {
    font-size: 19px; padding: 0 0 2px;}
.customSlideSideBar div.slideSidebarBox.after > div.sidebarBody > div.sidebarBodyInfo > div:last-child {
    width: 30%; padding: 0 0 6px;
    -webkit-display: flex; -ms-display: flex; -o-display: flex; display: flex;
    -webkit-justify-content: flex-end; -ms-justify-content: flex-end; -o-justify-content: flex-end; justify-content: flex-end;
    -webkit-align-items: flex-end; -ms-align-items: flex-end; -o-align-items: flex-end; align-items: flex-end;}
.customSlideSideBar div.slideSidebarBox.after > div.sidebarBody > div.sidebarBodyInfo > div:last-child > a,
.customSlideSideBar div.slideSidebarBox.after > div.sidebarBody > div.sidebarBodyInfo > div:last-child > button {
    color: #FFF; background-color: #e9001c; padding: 4px 10px; font-size: 14px;
    -webkit-border-radius: 12px; -ms-border-radius: 12px; -o-border-radius: 12px; border-radius: 12px;}

.customSlideSideBar div.slideSidebarBox > div.sidebarBody > div.sidebarBodyList {
    padding: 0 18px;
    font-size: 14px;
    color: #333;
}
.customSlideSideBar div.slideSidebarBox > div.sidebarBody > div.sidebarBodyList > ul {
    padding: 15px 0;
    margin: 0;
}
.customSlideSideBar div.slideSidebarBox > div.sidebarBody > div.sidebarBodyList > ul > li > a {
    display: block;
    padding: 15px 0;
}
/* side slide bar end */

/* header end */
/* new work! end */

/* PointComponent 시작*/
#cardPointBlock { margin-left:8px; margin-right: 8px;}
#cardPointBlock .user_check {padding:20px 0px; height:auto; margin:0 auto; text-align:center;}
#cardPointBlock img {width:100%;}
#cardPointBlock .user_check div {padding-top:10px;padding-left:10px; text-align:left;}
#cardPointBlock .w_txt {
    text-align:left; 
    letter-spacing:-.05em; 
    line-height:1.6; 
    padding:5px 20px; 
    background:#dcdcdc; 
}		
#cardPointBlock .q_go {background:#000; color:#fff; padding:0px 3px; text-decoration:none;}

/* PointComponent End */