.menu-item {
    padding: 0 22px;
    /* margin:0px 10px; */
    user-select: none;
    cursor: pointer;
    border: 0px transparent solid;
    width: 100% !important;
}
.menu-item.active {
    border: 0px green solid;
}
.scroll-menu-arrow {
    padding: 20px;
    cursor: pointer;
}

.scroll-menu-arrow--disabled {
    visibility: hidden;
}

.menu-wrapper--inner { display: flex;}
.menu-item-wrapper { display: flex !important;}
/* .menu-wrapper { margin-left: -10px;} */


@media screen and (max-width: 320px) {
    .menu-item {
        padding: 0 10px;
    }
}