@charset "utf-8";

/* default */
* {margin:0;padding:0;}
html {height:100%;font-size:62.5%;}
body {position: relative; height:100%;color:#333;font:1.4rem/1.5 Malgun Gothic,HelveticaNeue-Light,AppleSDGothicNeo-Light,sans-serif;-webkit-text-size-adjust:none;word-break:break-all;letter-spacing:-0.02em}

a {color:inherit;text-decoration:none;-webkit-tap-highlight-color: transparent; background: transparent;}
a:link { color: inherit; text-decoration: none;}
a:visited { color: inherit; text-decoration: none;}
a:hover { color: inherit; text-decoration: none;}
a:active { color: inherit; text-decoration: none;background: transparent;}
a:focus{ color: inherit; text-decoration: none;-webkit-tap-highlight-color:transparent;}
li {list-style:none;}
table {width:100%;border-collapse:collapse;border:0;table-layout:fixed;}
colgroup {background:none;}
small, input, select, textarea, button {font: 1.4rem/1.5 Malgun Gothic,HelveticaNeue-Light,AppleSDGothicNeo-Light,sans-serif;font-size:inherit;letter-spacing:-0.02em}
fieldset, img {border:0;}
img {max-width:100%;vertical-align:top;}
label, td img {vertical-align:middle;}
legend, caption, .hidden {position:absolute;z-index:-1;font:0/0 a;}
address, em {font-style:normal;}
button {overflow:visible;cursor:pointer;background:none;border:0;border-radius:0;}
button::-moz-focus-inner {padding:0;border:0;}

.inputTxt, select, textarea {
    height: 3.6rem;
    padding: 0 1rem;
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 0;
    color: #666;
    box-sizing: border-box;
    -webkit-appearance: none;
}
textarea {
    padding: 1rem;
    box-sizing: border-box;
    resize: none;
}

.section {
    padding: 0 1rem;
    border-top: .7rem solid #F3F3F3;
}

.subTitle1 {
    padding: 1rem 0 .5rem;
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0 0 0.75rem;
}
.subTitle1 span {
    font-weight: normal;
    font-size: 1rem;
}
.subTitle2 {
    font-size: 1.8rem;
}
.subTitle2 span {
    font-weight: normal;
    font-size: 1.4rem;
}

/* #header {
    position: relative;
} */
.main #header {position: relative; margin: 0 0 2.5rem;}
#header {
    position: relative;
    max-width: 568px;
    margin: 0 auto;
}
#header h1 {
    height: 4.4rem;
    text-align: center;
    font-size: 0;
    margin: 0;
}
.main #header h1 {
    height: 12.5rem;
    padding-top: 4.5rem;
}
#header h1:after {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}
#header h1 a {
    position: relative;
    z-index: 1;
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
}
#header h1 img {
    width: 2.35rem;
    vertical-align: middle;
}
.main #header h1 img {width: 4.5rem;}
.btnMenu {
    position: absolute;
    top: 1.3rem; left: 1.5rem;
    font-size: 2.5rem;
    color: #FFF;
}
#header .searchField {
    position: relative;
    margin: 0 1.5rem;
    padding-right: 4rem;
    border: .2rem solid #EA002C;
    border-radius: .3rem;
}
#header .searchField input[type="text"] {
    width: 100%;
    height: 3rem;
    padding: 0 1rem;
    border: 0;
    background: none;
    box-sizing: border-box;
    background-image: url('https://image.t-page.co.kr/resource/images/icon/search_google.png?v=2021022201');
    background-position: 0px center;
    background-repeat: no-repeat;
    background-size: contain;
    padding-left: 55px;
}

#header .searchField input[type="text"]:focus {
    outline: none;
}
.main #header .searchField input[type="text"] {height: 4.4rem;}
#header .searchField .btnSearch {
    position: absolute;
    top: 0; right: 0;
    width: 4rem; height: 3rem;
    background: url('https://image.t-page.co.kr/resource/images/icon/btn_search.png') no-repeat 50%;
    background-size: 1.8rem;
    font-size: 0;
}
.main #header .searchField .btnSearch {
    width: 4.5rem;
    height: 4.4rem;
}
#header .headNav {border-bottom: .7rem solid #F3F3F3;}
#header .headNav ul:after {
    content: "";
    display: block;
    clear: both;
}
#header .headNav li {
    float: left;
    width: 16.66%;
    text-align: center;
}
#header .headNav .dp1 li {
    width: 20%;
}
#header .headNav a > span {
    display: inline-block;
    padding: 1rem 0;
    border-bottom: .25rem solid transparent;
    line-height: 1;
    font-weight: bold;
}
#header .headNav .active a > span {
    border-color: #EA002C;
    color: #EA002C;
}

.menu-item-wrapper{-webkit-tap-highlight-color: #fff; outline: none;}


/* new work */
#header div.dp2 {border-top: .7rem solid #F3F3F3;}
#header div.dp2 .deps2-menu-item > a {
    padding: 12.5px 16px;
    border: 0;
}
#header div.dp2 .deps2-menu-item > a > span {
    font-weight: 400;
}
/* new work end */

.contentNav ul:after {
    content: "";
    display: block;
    clear: both;
}
.contentNav li {
    float: left;
    width: 16.66%;
    text-align: center;
}
.contentNav a {
    display: inline-block;
    padding: 1rem 0;
    border-bottom: .25rem solid transparent;
    line-height: 1;
    font-weight: bold;
}
.contentNav .active a {
    border-color: #EA002C;
    color: #EA002C;
}

#footer {
    position: relative;
    padding: 2rem .5rem;
    background: #333333;
    text-align: center;
    color: #b5b5b5;
    max-width: 568px;
    margin: .3rem auto 0;
}
#footer .privacy {font-size: 0;}
#footer .privacy span {
    position: relative;
    display: inline-block;
    padding: 0 .8rem;
    font-size: 1rem;
    line-height: 1;
}
#footer .privacy span + span:before {
    content: "";
    position: absolute;
    top: .1rem; left: 0;
    width: .05rem; height: .8rem;
    background: #B8B8B8;
}
#footer small {
    display: block;
    margin: .6rem 0 0;
    font-size: 1rem;
}
#footer .links {
    width: 100%;
    overflow: hidden;
    margin: 0 0 1rem;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
#footer .links span {
    float: left;
    width: 33.33%;
    font-size: 1rem;
}
#footer .links a {
    display: block;
    line-height: 3rem;
    margin: .5rem;
    color: #f4f4f4;
    background: #707070;
}

.ftColor1 {color:#3A8ACF;}

.mapList {margin: 0 -1rem;}
.mapList li {position:relative;padding:1.2rem 7.5rem 1.5rem 6.5rem;border-top:1px solid #D5D5D5;}
.mapList a {display:block;}
.mapList img {position:absolute;top:50%;left:2.5rem;width:2rem;transform:translateY(-50%);}
.mapList .name {display:block;font-size:1.3rem;    text-overflow:ellipsis;
    white-space:nowrap;
    word-wrap:normal;
    overflow:hidden;}
.mapList .address {display:block;color:#B9B9B9;font-size:1.0rem;
    text-overflow:ellipsis;
    white-space:nowrap;
    word-wrap:normal;
    overflow:hidden;
}
.mapList .range {position:absolute;top:0;right:2.5rem;bottom:0;color:#E34549;font-size:1.2rem;}
.mapList .range:after {content:"";display:inline-block;height:100%;vertical-align:middle;}

